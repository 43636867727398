.graphList ul {
    padding: 0;
    margin: 0;
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
}

.graphList ul li {
    list-style: none;
    width: 25%;
    padding: 0 15px;
}

.innerGraph {
    border: 0.5px solid #03fe9d42;
    border-radius: 10px;
    padding: 15px 17px 12px;
    background: #111213;
}

.topNameGraph {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topNameGraph h6 {
    font-size: 13px;
    color: #FFFFFF;
}

.topNameGraph h5 {
    font-size: 12px;
    color: #50E3C2;
}

.innerGraph h4 {
    font-size: 17px;
    color: #ffffffc4;
    margin: 8px 0 0;
}

.graphList ul li.active .innerGraph {
    background: #03FE9D;
    color: #000;
}

.graphList ul li.active h4 {
    color: #000;
    opacity: 0.7;
}

.graphList ul li.active h6 {
    color: #000;
}

.graphList ul li.active h5 {
    color: #000;
}

.graphList {
    margin-top: 50px;
}

.lineChart {
    margin-top: 14px;
    min-height: 60px;
}

.lineChartLeft img {
    width: 100% !important;
}

.linechart {
    margin-top: 40px;
}

.lineChartRight {
    background: #111213;
    padding: 25px;
    border-radius: 10px;
    height: 100%;
}

.chartTopHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chartTopHead h4 {
    font-size: 19px;
    color: #fff;
}

.chartTopHead h4 img {
    margin-right: 15px;
}

.chartTopHead a {
    font-size: 14px;
    color: #77ED91;
    text-decoration: none;
}

.lineChartRight ul li {
    list-style: none;
    display: flex;
    margin-top: 25px;
}

.lineChartRight ul {
    padding: 0;
    margin: 0;
}

.lineChartRight ul li span img {
    max-width: 34px;
    max-height: 34px;
    border-radius: 100px;
    margin-right: 11px;
}

.pageNotify h6 {
    font-size: 17px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3px;
}

.pageNotify h6 label {
    color: #A1A1A1;
    font-weight: 300;
    font-size: 13px;
}

.pageNotify p {
    margin: 0;
    color: #A1A1A1;
    font-size: 14px;
    font-weight: 400;
    max-width: 90%;
}

.pageNotify {
    width: 100%;
}

.tableData {
    margin-top: 35px;
}

.tableData h3 {
    font-size: 20px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 15px;
}

.tableData table tr th {
    background: #1A1D1E;
    padding: 11px 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
}

.tableData table {
    width: 100%;
}

.tableData table tr td {
    padding: 11px 20px;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
}

.tableData table tr td img {
    margin-right: 5px;
}

.tableData table tr td label {
    color: #A1A1A1;
    font-size: 12px;
}

.tableData table tr td.green {
    color: #03FE9D;
}

.tableData table tr td.red {
    color: #F92C2C;
}

.tableData table span {
    border: 1px solid #212325;
    width: 33px;
    height: 33px;
    display: flex;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
}

.tableData table tr td span img {
    margin: 0;
}

.topcompanyHead h4 {
    font-size: 24px;
    color: #fff;
    margin: 0;
}

.topcompanyHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 37px 0 16px;
}

.topcompanyHead a {
    border: 1px solid #909093;
    font-size: 14px;
    color: #c6c6c6;
    text-decoration: none;
    padding: 10px 14px;
    border-radius: 21px;
}

.topcompanyHead a i {
    font-size: 12px;
    margin-left: 7px;
}

.innerComapnay {
    background: #151718;
    border-radius: 18px;
    padding: 22px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.topIc {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 15px;
}

.innerComapnay h6 {
    font-size: 16px;
    color: #fff;
}

.growrateLeft {
    background: #151718;
    border-radius: 20px;
    margin-top: 26px;
    padding: 24px;
}

.innerComapnay h5 {
    font-size: 28px;
    color: #fff;
    font-weight: 600;
}

.innerComapnay h4 {
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    margin-top: 120px;
    margin-bottom: 0;
}

.innerComapnay::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    background-image: url("../../../assets/images/bkline.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 250px;
    bottom: 0;
    background-size: cover;
    pointer-events: none;
    z-index: -1;
}

.innerComapnay h4.Gren {
    color: #2DC24E;
}

.innerComapnay h4 span {
    font-size: 13px;
    color: #A1A1A1;
}

.innerComapnay h4.Redd {
    color: #F92C2C;
}

.active.innerComapnay {
    background-image: url("../../../assets/images/shade.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.active.innerComapnay::before {
    background-image: url("../../../assets/images/lines.png");
}

.active.innerComapnay h6,
.active.innerComapnay h5,
.active.innerComapnay h4 {
    color: #000;
}

.active.innerComapnay h4 span {
    color: #000;
}

.growrateLeftHead,
.growrateRightHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.growrateLeftHead h4,
.growrateRightHead h4 {
    font-size: 26px;
    color: #fff;
    margin: 0;
}

.growrateRightHead h4 img {
    margin-right: 7px;
}

.gowlitBtn {
    padding: 20px 0 10px;
}

.growrateRightHead a {
    font-size: 15px;
    text-decoration: none;
    color: #77ED91;
}

.groBtn {
    background: #212325;
    border-radius: 100px;
    padding: 8px;
}

.groBtn button {
    background: #212325;
    border-radius: 100px;
    font-size: 15px;
    color: #fff;
    border: 0;
    padding: 10px 57px;
    font-weight: 600;
}

.groBtn button.active {
    background: #fff;
    border-radius: 100px;
    font-size: 15px;
    color: #000;
    border: 0;
    padding: 10px 57px;
    font-weight: 600;
}

.gowlitBtn button {
    color: #fff;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 100px;
    padding: 9px 22px;
    margin-right: 9px;
}

.gowlitBtn button.active {
    background: #fff;
    border: 1px solid #fff;
    border-radius: 100px;
    padding: 9px 22px;
    margin-right: 9px;
    color: #000;
}

.growList ul li,
.growrateRight ul li {
    background: #212325;
    border-radius: 100px;
    padding: 16px 20px;
    margin: 13px 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.growrateRight ul li span img {
    max-width: 25px;
    margin: 0 6px;
}

.growList ul li label {
    color: #fff;
    font-size: 15px;
}

.growList ul li span {
    color: #1ffaa2;
}

.growList ul li span img {
    margin-right: 6px;
}

.growrateRight ul li h6 {
    color: #fff;
    font-size: 15px;
    margin: 0;
}

.growList ul,
.growrateRight ul {
    padding: 0;
    margin: 0;
}

.growrateRight {
    background: #151718;
    border-radius: 20px;
    margin-top: 26px;
    padding: 24px;
}

.growrateRightHead {
    margin-bottom: 32px;
}

.growrateRight ul li span {
    color: #fff;
    font-size: 15px;
}

.newGrow img {
    width: 100% !important;
}

.newGrow {
    margin-top: 25px;
}