.paginationContainer {
    display: flex;
    justify-content: space-around;
    background-color: #0f1111;
    color: white;
    padding: 10px;
    text-align: center;
    border-radius: 30px;
    font-weight: 800;
    font-size: large;
    margin: 20px auto;
    width: fit-content;
}

.page {
    list-style: none;
    width: 40px;
    height: 40px;
    color: #ffffff;
    background-color: #0f1111;
    padding: 6px 0px;
    margin: 0px 10px;
    border-radius: 4px;
    font-weight: 500;
}

.pageLink {
    text-decoration: none;
    color: #fff;
}

.selected {
    background-color: #1ffe9d !important;
    border-collapse: separate;
    border-spacing: 15px;
}
.selected .pageLink
{
    color: #000;
}

.paginateButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1a1d1e;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.paginateLink {
    text-decoration: none;
    font-weight: 200;
    color: #1ffe9d;

}
@media (max-width:767px)
{
    .page {
       
        width: 30px;
        height: 30px;
        padding: 3px 0px;
        margin: 0px 5px;
    }
}