.changePasswordModal h4 {
    text-align: center;
    color: #fff;
    font-size: 26px;
    margin-bottom: 23px;
}

.changePasswordModal form .formGroup label {
    font-size: 14px;
    width: 100%;
    color: #fff;
    margin-bottom: 6px;
}

.changePasswordModal form .formGroup input {
    height: 50px;
    width: 100%;
    border-radius: 100px;
    padding: 0 19px;
    border: 1px solid #6B6B6B;
    background: #000;
    font-size: 14px;
    outline: none;
    color: white;
}

.changePasswordModal form .formGroup {
    margin-bottom: 15px;
    position: relative;
}

.changePasswordModal form input[type="submit"] {
    background: #03FE9D;
    font-weight: 600;
    border: 0;
    font-size: 16px;
    margin-top: 10px;
    color: black;
}

.changePasswordModal form .formGroup span img {
    max-width: 19px;
}

.changePasswordModal form .formGroup span {
    position: absolute;
    right: 20px;
    top: 35px;
}

.PrfileIamge {
    width: 104px;
    height: 106px;
    display: flex;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    pointer-events: none;
}

.editIamge {
    width: 116px;
    height: 116px;
    margin: 0 auto 32px;
    position: relative;
}

.editIamge label {
    color: #fff;
    font-size: 12px;
}

.editIamge input {
    font-size: 0;
    border: 0;
    padding: 25px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.modalContent {
  background-color: #0D0D0D !important;
  padding: 40px 20px;
}

.modalDialog {
  background-color: #0D0D0D !important;
}

.modalDialog div:first-child {
    color: white !important;
}