.SeoOuter {
    background: #101111;
    border-radius: 20px;
    padding: 30px;
    margin-top: 25px;
}
.SeoOuter h4 {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 23px;
}
.leftSeo form label {
    width: 100%;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 6px;
}
.leftSeo form input {
    background: #000;
    border: 1px solid #6B6B6B;
    border-radius: 100px;
    height: 50px;
    padding: 20px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}
.customInput {
    background: #000;
    border: 1px solid #6B6B6B;
    border-radius: 100px;
    height: 50px;
    padding: 20px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    display: flex;
    align-items: center;
}
.leftSeo form .FormGroup {
    margin-bottom: 19px;
    position: relative;
}
.leftSeo form .FormGroup textarea {
    background: #000;
    border: 1px solid #6B6B6B;
    border-radius: 20px;
    height: 300px;
    padding: 20px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}
.leftSeo form .FormGroup input[type="submit"] {
    height: 55px;
    border: 0;
    background: #03FE9D;
    font-size: 18px;
    padding: 0;
    color: #fff;
}
.leftSeo ul {
    padding: 13px 0 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}
.leftSeo ul li {
    margin-right: 9px;
    list-style: none;
}
.leftSeo ul li span {
    height: 40px;
    background: #000;
    border: 1px solid #6B6B6B;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 38px;
    margin: 7px 0;
    color: #fff;
    font-size: 14px;
}
.leftSeo form .FormGroup input[type="checkbox"] {
    width: 33px;
    height: 18px;
    padding: 0;
    background-repeat: no-repeat;
    background-color: #03FE9D;
    position: absolute;
    right: 22px;
    top: 42px;
}
.rightSeo {
    background: #00000026;
    border: 1px solid #FFFFFF80;
    border-radius: 13px;
    padding: 40px;
}
.infoIC {
    margin-bottom: 15px;
}
.rightSeo p {
    color: #fff;
    margin: 5px 0;
    font-size: 15px;
    font-weight: 500;
}
.error {
    color: rgb(247, 0, 0);
}