.topNotify ul li {
    margin-right: 41px;
    list-style: none;
}

.topNotify ul li a {
    color: #CECECE;
    text-decoration: none;
}

.topNotify {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.topNotify ul {
    padding: 0;
    margin: 60px 0 0px;
    display: flex;
}

.topNotify ul li.active a {
    color: #1ffe9e;
    font-weight: 700;
}

.notificationList h6 {
    font-size: 16px;
    color: #FFFFFF;
    margin: 20px 0 20px;
}

.notificationList ul {
    padding: 0;
    margin: 0;
}

.notificationList ul li {
    border: 1px solid #797979;
    background: #000;
    padding: 14px;
    border-radius: 10px;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    position: relative;
}

.notificationList ul li span {
    width: 52px;
    height: 52px;
    background: #2880da1a;
    border-radius: 8px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notificationList ul li h5 {
    font-size: 16px;
    color: #fff;
    margin: 0;
}

.notificationList ul li p {
    margin: 0;
    color: #fff;
    font-size: 14px;
    opacity: 0.5;
}

.notificationList ul li label {
    margin-left: auto;
    padding-right: 10px;
}

.topNotify button {
    color: #03FE9D;
    background: transparent;
    border: 1px solid #03FE9D;
    border-radius: 100px;
    padding: 10px 20px;
}

.loading {
    color: #fff;
    text-align: center;
    margin-top: 100px;
    font-size: 22px;
}