.button {
    background: #03FE9D;
    color: #000;
    text-decoration: none;
    font-size: 15px;
    padding: 9px 23px;
    font-weight: 600;
    border-radius: 10px;
    border: 0px;
}

.close {
    background: #ec2004;
    color: white;
    text-decoration: none;
    font-size: 15px;
    padding: 9px 23px;
    font-weight: 600;
    border: 0px;
    border-radius: 10px;
}

.modal {
    background-color: #0D0D0D !important;
}

.content {
    text-align: center;
    font-size: 17px;
    background: #0D0D0D;
    color: #fff;
}

.faqContent {
    background: #0D0D0D;
    color: #fff;
}

.modHead {
    background: #0D0D0D;
    color: #fff;
    border: 0;
}

.modFoter {
    background: #0D0D0D !important;
    border: 0;
}

.input {
    border: 1px solid #4F4F4F;
    width: 100%;
    background: transparent;
    border-radius: 100px;
    height: 55px;
    font-weight: 400;
    padding: 0 21px;
    font-size: 18px;
    color: #9A9AB0;
    outline: none;
}

.textarea {
    border: 1px solid #4F4F4F;
    width: 100%;
    background: transparent;
    border-radius: 10px;
    height: 180px;
    font-weight: 400;
    padding: 10px 21px;
    font-size: 18px;
    color: #9A9AB0;
    outline: none;
}

.editor {
    height: 40vh !important;
    width: 100% !important;
    overflow: auto !important;
}

.subLabel {
    font-size: 13px;
}