.newBlog {
  background: #03FE9D;
  border: 0;
  border-radius: 100px;
  padding: 9px 28px;
  font-weight: 600;
  margin-left: auto;
  min-width: 170px;
}

.filterSlect input {
  height: auto;
  color: white;
}

.filterSlect {
  width: 40%;
}

.blogImage {
  border-radius: 6px;
}