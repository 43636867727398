.sidebar {
    background: #15161766;
    width: 14%;
    padding: 30px 25px 0;
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
}

.sidebar ul {
    padding: 0;
    margin: 0;
}

.sidebar ul li {
    list-style: none;
}

.sidebar ul li a {
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    padding: 14px 8px;
    display: flex;
    align-items: center;
    border-radius: 100px;
}

.sidebar ul li a img {
    margin-right: 0px;
}

.sidebar ul li.active a {
    background: #03FE9D;
    color: #000;
    font-weight: 500;
}

.sidebar ul li a span {
    width: 38px;
    height: 38px;
    border-radius: 100px;
    background: #0f1111;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}

.top-logo {
    text-align: center;
    margin-bottom:30px;
}

.logout {
    margin-top: 50px;
}


@media (max-width:1650px) {

    .top-logo img {
        width: 100%;
        max-width: 100%;
    }

    .sidebar {
        width: 18%;
        padding: 17px 12px 0;
    }

    .top-logo {
        margin-bottom: 30px;
    }
}

@media (max-width:1300px) {
    .sidebar ul li a {
        padding: 9px 8px;
    }
}

@media (max-width:1199px) {
    .sidebar {
        width: 30%;
        padding: 17px 12px 0;
        position: absolute;
        z-index: 9;
        background: #1a1d1e;
        opacity: 0;
        display: none;
    }
}
