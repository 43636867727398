.topBarLeft h3 {
    font-size: 32px;
    color: #fff;
    font-weight: 400;
    margin: 0;
}

.topBarLeft span {
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    color: #909093;
    margin: 0;
}

.topBarRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.topBarRight .form-group {
    position: relative;
    min-width: 264px;
}

.topBarRight .form-group input {
    height: 50px;
    width: 100%;
    border-radius: 100px;
    padding: 0 20px;
    background: #151718;
    border: 0;
    padding-left: 57px;
    font-style: italic;
    outline: none;
    color: #fff;
}

.topBarRight .form-group span {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background: #212325;
    top: 4px;
    position: absolute;
    left: 5px;
}

.userSelect span img {
    border-radius: 100px;
    max-width: 50px;
    max-height: 50px;
    margin-right: 6px;
}

.userSelect label {
    font-size: 16px;
    color: #fff;
}

.notification {
    width: 50px;
    height: 50px;
    background: #151718;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    MARGIN: 0 19px;
}

.notification span {
    position: absolute;
    background: #EA2D2D;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 7px;
    right: 10px;
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    border-radius: 100px;
    PADDING: 1px 0 0;
}
.topBarLeft {
    display: flex;
    align-items: center;
}
span.Toggle {
    color: #fff;
    font-size: 23px;
    margin-right: 15px;
    cursor: pointer;
}
@media (max-width:767px)
{
    .topBarLeft h3 {
        font-size: 22px;
        margin: 0 0 0px;
    }
    .topBarRight .form-group {
        min-width: auto;
        display: none;
    }
    .userSelect {
        display: flex;
        align-items: center;
        padding-left: 13px;

    }
    
}