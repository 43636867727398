.uploadDoc {
    padding: 80px 0;
    background: #000000;
}

.uploadDoc h3 {
    text-align: center;
    margin-bottom: 40px;
}

.outerUpoad {
    max-width: 750px;
    margin: 0 auto;
}

.innerUpload {
    background: #14222D;
    padding: 31px;
    border-radius: 10px;
}

.innerUpload h5 {
    font-size: 20px;
    color: #fff;
    margin: 0 0 13px;
}

.innerUpload h6 {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 13px;
    margin: 23px 0 18px;
}

.innerUpload p {
    margin: 0;
    font-size: 12px;
    color: #fff;
    opacity: 0.6;
}

.uploadFrame {
    position: relative;
    width: 100%;
    height: 140px;
    margin-top: 15px;
}

.uploadFrame input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    font-size: 0;
    padding: 30px;
}

.uploadFrame span {
    background: #000;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #2189CF;
    color: #2189CF;
    font-size: 25px;
    border-radius: 9px;
    pointer-events: none;
}

span.tagName {
    text-align: center;
    color: #fff;
    width: 100%;
    display: table;
    margin-top: 11px;
    font-size: 15px;
}

.uploadFrame img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 10px;
    object-fit: cover;
}

.submit {
    background-image: linear-gradient(to right, #2CA8EF, #03FE9D);
    background: #03FE9D;
    color: #000;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 0;
    border-radius: 5px;
    padding: 12px 0;
    padding: 10px 15px;
    margin-top: 16px;
}
.uploadFrame img {
    transition: transform 0.2s; /* Animation */
    width: 100%;
    height: auto;
    height: 140px !important;
  }
/*   */

.error {
    color: red;
    font-size: 13px;
    margin-bottom: 0px;
    margin-left: 5px;
    margin-top: 3px;
    text-align: center;
}
