.input {
  height: 50px;
  width: 100%;
  border-radius: 100px;
  padding: 0 19px;
  border: 1px solid #6B6B6B;
  background: #000;
  font-size: 14px;
  outline: none;
  color: white;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 100px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: #000;
  transition: .4s;
  border-radius: 100px;

}

.switch input:checked+.slider {
  background-color: #03fe9d;
}

.switch input:focus+.slider {
  box-shadow: 0 0 1px #03fe9d;
}

.switch input:checked+.slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

.slider .round {
  border-radius: 34px;
}

.slider .round:before {
  border-radius: 50%;
}

.loyalityPoints {
  overflow: hidden;
}

.loyalityPoints h3 {
  color: #fff;
  font-weight: 600;
  margin-top: 30px;
}

.loyaltyCards {
  background: #1A1D1E;
  padding: 20px;
  border-radius: 10px;
}

.loyaltyCards p {
  color: #fff;
  margin: 0 0 5px;
}

.loyaltyCards input {
  width: auto;
  min-width: 410px;
}

.updatePoints {
  background: #03FE9D;
  border: 0;
  border-radius: 100px;
  padding: 9px 28px;
  font-weight: 600;
  margin-left: auto;
}

@media (max-width:1650px) {
  .tableData table tr th {
    padding: 11px 13px;
  }

  .tableData table tr td {
    padding: 11px 13px;
  }
}

@media (max-width:1400px) {
  .scrollTable table {
    width: 113%;
  }

  .scrollTable {
    overflow-x: auto;
  }
}

@media (max-width:1300px) {
  .filterSlect {
    width: 67% !important;
  }

  .scrollTable table {
    width: 125%;
  }
}

@media (max-width:767px) {
  .topFilter {
    flex-wrap: wrap;
  }

  .topFilter input {
    width: 100%;
    margin-bottom: 10px;
  }

  .filterSlect {
    width: 100% !important;
    flex-wrap: wrap;
  }

  .scrollTable table {
    width: 345%;
  }
}