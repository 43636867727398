.settings {
  background: #1A1D1E;
  padding: 20px;
  border-radius: 10px;
  margin-top: 40px;
}

.input {
  height: 50px;
  width: 100%;
  border-radius: 100px;
  padding: 0 19px;
  border: 1px solid #6B6B6B;
  background: #000;
  font-size: 14px;
  outline: none;
  color: white;
}

.submit {
  background: #03FE9D;
  border: 0;
  border-radius: 100px;
  padding: 9px 28px;
  font-weight: 600;
  margin-left: auto;
}

.settingTogles {
  display: flex;
  align-items: center;
}

.settingTogles button {
  padding: 13px 50px;
}

.PopcountrBox {
  background: #0d0d0d;
  padding: 14px;
  border-radius: 6px;
  margin-top: 19px;
  text-align: center;
  position: relative;
}

.PopcountrBox p {
  margin: 16px 0 0;
}

h6.Lenght {
  position: absolute;
  right: 16px;
  top: 16px;
  margin: 0;
  color: #000;
  background: #1ffe9d;
  width: 21px;
  height: 21px;
  border-radius: 100px;
  font-size: 13px;
  FONT-WEIGHT: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}