.addCoupon form .fromGroup label {
    font-size: 14px;
    width: 100%;
    color: #fff;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
}

.input, .addCoupon form .fromGroup select {
    height: 50px;
    width: 100%;
    border-radius: 100px;
    padding: 0 19px;
    border: 1px solid #6B6B6B;
    background: #000;
    font-size: 14px;
    outline: none;
    color: white;
}

.addCoupon form .fromGroup {
    margin-bottom: 15px;
    position: relative;
}
.submit {
    background: #03FE9D;
    font-weight: 600;
    border: 0;
    font-size: 16px;
    margin-top: 10px;
    color: black;
    padding: 13px 80px;
    border-radius: 100px;
}
.fileTypee {
    background: #192328;
    border-radius: 10px;
    height: 155px;
    position: relative;
}
.addCoupon form .fromGroup .fileTypee input {
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent;
    font-size: 0;
    border: 0;
    padding: 60px;
}
.fileBtns {
    background: #192328;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    pointer-events: none;
}
.fileBtns span {
    border: 1px solid #03FE9D;
    border-radius: 4px;
    color: #fff;
    background: #000;
    padding: 5px 10px;
    margin: 6px 0 0;
    font-size: 13px;
}
.fileBtns p {
    font-size: 13px;
    color: #fff;
    margin: 5px 0 0;
}
.addCoupon h4
{
    color: #fff;
    font-size: 26px;
    text-align: center;
    margin: 34px 0 0;
}
.subLabel {
    font-size: 14px;
    color: #fff;
}
.css-13cymwt-control {
    background: #000 !important;
    border: 1px solid #6B6B6B !important;
    border-radius: 100px !important;
    padding: 6px 0px !important;
  }
  @media (max-width:767px)
  {
    .addCoupon h4 {
        font-size: 20px;
        margin: 23px 0 0;
    }
  }