button.assCoupon {
    background: #03FE9D;
    border: 0;
    border-radius: 100px;
    padding: 9px 28px;
    font-weight: 600;
    margin-left: auto;
}

@media (max-width:1650px) {
    .tableData table tr th {
        padding: 11px 13px;
    }

    .tableData table tr td {
        padding: 11px 13px;
    }
}

@media (max-width:1400px) {
    .scrollTable table {
        width: 113%;
    }

    .scrollTable {
        overflow-x: auto;
    }
}

@media (max-width:1300px) {
    .filterSlect {
        width: 67% !important;
    }

    .scrollTable table {
        width: 100%;
    }
}

@media (max-width:767px) {
    .topFilter {
        flex-wrap: wrap;
    }

    .topFilter input {
        width: 100%;
        margin-bottom: 10px;
    }

    .filterSlect {
        width: 100% !important;
        flex-wrap: wrap;
    }

    .scrollTable table {
        width: 235%;
    }
}