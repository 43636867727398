.tableData {
    margin-top: 35px;
}

.tableData h3 {
    font-size: 20px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 15px;
}
.topFilter input {
    height: auto !important;
}
 td.ViewBTn
{
    color: red;
}
.tableData table tr th {
    background: #1A1D1E;
    padding: 20px 15px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
}
.simBarTab ul li {
    margin-right: 12px;
  }
  .simBarTab ul li p {
    margin: 0;
    border: 1px solid #fff;
    padding: 11px 14px;
    border-radius: 7px;
  }
.tableData table tr:nth-child(2n) td {
    background: #1a1d1e;
}
.tableData table {
    width: 100%;
}
.tableData table tr td .loyalityPointBtn
{
    background: #03FE9D;
    border: 0;
    border-radius: 100px;
    padding: 6px 13px;
    font-weight: 600;
    margin-left: auto;
    margin-bottom: 0;
}

.tableData table tr td {
    padding: 20px 15px;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
}

.tableData table tr td img {
    margin-right: 5px;
}

.tableData table tr td label {
    color: #A1A1A1;
    font-size: 12px;
}

.tableData table tr td.green {
    color: #03FE9D;
}

.tableData table tr td.red {
    color: #F92C2C;
}

.tableData table span {
    border: 1px solid #212325;
    width: 33px;
    height: 33px;
    display: flex;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
}

.tableData table tr td span img {
    margin: 0;
}

.topFilter ul {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
}

.topFilter ul li {
    list-style: none;
    margin-left: 40px;
}

.topFilter ul li span {
    font-size: 16px;
    color: #96A5B8;
    padding-right: 10px;
}

.topFilter ul li select {
    background: transparent;
    border: 0;
    font-size: 16px;
    color: #fff;
    padding-right: 4px;
}

.topFilter ul li select {
    background: transparent;
    border: 0;
    font-size: 16px;
    color: #fff;
    padding-right: 4px;
    outline: none;
}

.topFilter {
    padding-bottom: 17px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.topFilter input {
    height: 50px;
    width: auto;
    border-radius: 100px;
    padding: 0 20px;
    background: #151718;
    border: 0;
    padding-left: 25px;
    font-style: italic;
    outline: none;
    color: #fff;
}

.active .sidebar ul li a {
    font-size: 0;
}

.active .sidebar ul li.active a {
    background: #03FE9D;
    color: #000;
    font-weight: 500;
    padding: 2px;
    text-align: center;
}

.active .sidebar ul li.active a span {
    width: 58px;
    height: 38px;
    margin: 0;
}

.active .right-bar {
    width: 95% !important;
}

.active .sidebar {
    width: 5%;
}

.active .sidebar .top-logo img {
    max-width: 60px;
}
@media (max-width:1199px)
{
    .active .sidebar {
        width: 21%;
        opacity: 1;
        display: block;
    }
    .active .right-bar {
        width: 100% !important;
    }
    .active .sidebar ul li a {
        font-size: 15px;
    }
}
@media (max-width:991px) {
    .active .sidebar {
        width: 33%;
        opacity: 1;
        display: block;
    }

    .active .sidebar ul li a {
        font-size: 15px;
    }
    .active .right-bar {
        width: 100% !important;
    }
    .active .sidebar ul li.active a span {
        width: 36px;
        height: 38px;
        margin: 0px 10px 0 0;
    }

    .active .sidebar .top-logo img {
        max-width: 130px;
    }
}
@media (max-width:767px)
{
    .active .sidebar {
        width: 73%;
    }
    .topFilter {
        flex-wrap: wrap;
    }
    .topFilter input {
        width: 100%;
        margin-bottom: 20px;
    }
}


.sidebar {
    background: #15161766;
    width: 14%;
    padding: 30px 13px 0;
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
}

.sidebar ul {
    padding: 0;
    margin: 0;
}

.sidebar ul li {
    list-style: none;
}
.sidebar ul li a {
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    padding: 10px 8px;
    display: flex;
    align-items: center;
    border-radius: 100px;
}

.sidebar ul li a img {
    margin-right: 0px;
}
.sidebar ul li.active a {
    background: #03FE9D;
    color: #000;
    font-weight: 500;
}
.inner-bner span.typewriter {
    font-size: 130px;
    color: #fff;
    font-weight: 800;
}
.sidebar ul li a span {
    width: 38px;
    height: 38px;
    border-radius: 100px;
    background: #0f1111;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}

.top-logo {
    text-align: center;
    margin-bottom: 30px;
}

.logout {
    margin-top: 50px;
}
.mainTabbing {
    display: flex;
    justify-content: space-between;
    padding-bottom: 21px;
}

.topCms ul {
    padding: 0;
    margin: 0;
    display: flex;
}

.topCms ul li {
    list-style: none;
    margin-right: 33px;
    cursor: pointer;
}

.topCms ul li p {
    color: #fff;
    text-decoration: none;
}
.topCms ul li.active p {
    color: #03FE9D;
    font-weight: 600;
    border-bottom: 2px solid #03FE9D;
    padding-bottom: 7px;
}

div:where(.swal2-container) div:where(.swal2-popup) {
    width: 25em !important;
}

@media (max-width:1650px) {

    .top-logo img {
        width: 100%;
        max-width: 100%;
    }

    .sidebar {
        width: 18%;
        padding: 17px 12px 0;
    }

    .top-logo {
        margin-bottom: 30px;
    }
}

@media (max-width:1300px) {
    .sidebar ul li a {
        padding: 9px 8px;
    }
}

@media (max-width:1199px) {
    .sidebar {
        width: 30%;
        padding: 17px 12px 0;
        position: absolute;
        z-index: 9;
        background: #1a1d1e;
        opacity: 0;
        display: none;
    }
}


.right-bar {
    width: 86%;
    padding: 30px 40px;
    overflow-y: auto;
    height: 100vh;
}

@media (max-width:1650px) {
    .right-bar {
        width: 82%;
        padding: 21px 25px;
    }

}

@media (max-width:1199px) {
    .right-bar {
        width: 100%;
        padding: 21px 15px;
    }
}