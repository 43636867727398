body {
  font-family: "Urbanist", sans-serif !important;
}

img {
  position: static !important;
  /* width: auto !important; */
  height: auto !important;
}

.loadmore {
  text-align: center;
  margin: 0 auto;
  background: #1ffe9d;
  display: table;
  padding: 9px 24px;
  border-radius: 100px;
  color: #000 !important;
  font-weight: 500;
  cursor: pointer;
}

.rc-md-editor .rc-md-navigation {
  border-bottom: 1px solid #0f1111 !important;
  background: #0f1111 !important;
  color: #fff !important;
}
.form-check.form-switch.cst-check {
  position: absolute;
  right: 0;
  top: 0;
}
.rc-md-editor .editor-container .sec-md .input {
  background: #0f1111 !important;
  color: #fff !important;
}

.rc-md-editor {
  border: 1px solid #0f1111 !important;
  background: #0f1111 !important;
  color: #fff !important;
}

.rc-md-editor .rc-md-navigation i {
  color: #ffffffb5;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #03fe9d;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #03fe9d;
}

.modal-header {
  border: 0;
  text-align: center;
  justify-content: center;
  padding-bottom: 0;
}

.modal-header .modal-title.h4 {
  width: 100%;
}

.modal-body {
  padding: 30px 33px !important;
}

button.btn-close {
  background-color: #fff;
  opacity: 1;
  border-radius: 100px;
  background-size: 11px;
  padding: 0;
  width: 11px;
  height: 10px;
}

.modal-content {
  background: #0d0d0d  !important;
}

.cursor-pointer {
  cursor: pointer;
}

.react-datepicker-wrapper {
  width: 100%;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  background: #000 !important;
  border: 1px solid #6B6B6B !important;
  border-radius: 100px !important;
  padding: 6px 0px !important;
  outline: none !important;
}

span.error {
  color: red;
  padding-left: 6px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.css-1nmdiq5-menu div
{
  color: #000 !important;
}
@media (max-width:991px) {
  .ProfielView .col-md-6 {
    width: 100%;
    margin-bottom: 20px;
  }
}
