
.profileData {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D9D9D94D;
    padding-bottom: 40px;
    margin-bottom: 40px;
    margin-top: 60px;
}
.topCms {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 70px 0 40px;
}

.topCms ul {
    padding: 0;
    margin: 0;
    display: flex;
}

.topCms ul li {
    list-style: none;
    margin-right: 33px;
    cursor: pointer;
}
.topCms ul li p {
    margin: 0;
    border: 1px solid #fff;
    padding: 11px 14px;
    border-radius: 7px;
    color: #fff;
    text-decoration: none;

}
.topCms ul li.active p {
    border: 1px solid ! IMPORTANT;
    padding: 11px 14px !important;
    border-radius: 7px ! IMPORTANT;
    font-weight: 5 !important;
}
.topCms ul li.active p {
    color: #03FE9D;
    font-weight: 600;
    border-bottom: 2px solid #03FE9D;
    padding-bottom: 7px;
}
.topFilter input
{
    height: auto !important;
}
 td.ViewBTn a
{ 
    color: #1ffe9d !important
}
.rightUser button {
    background: #1A1D1E;
    border: 0;
    color: #fff;
    font-size: 16px;
    border-radius: 100px;
    padding: 10px 30px;
    margin-left: 12px;
}

.rightUser button img {
    margin-right: 5px;
}

.rightUser button.detet {
    color: #FF0000;
}

.filterHead h4 {
    font-size: 28px;
    color: #fff;
    margin-bottom: 10px;
}

td.green {
    color: #03FE9D;
}

.rightUser span {
    font-size: 16px;
    background: #77ED91;
    padding: 10px 24px;
    border-radius: 60px;
    font-weight: 600;
    margin-left: 12px;
}

.usrData h6 {
    color: #0ECA38;
    font-size: 13px;
    font-style: italic;
    margin: 0;
}

.usrData h5 {
    font-size: 28px;
    color: #fff;
    margin: 6px 0;
}

.leftUser p {
    font-size: 15px;
    color: #fff;
    margin: 0;
}

.leftUser {
    display: flex;
    align-items: center;
}

.leftUser img {
    margin-right: 10px;
}

.filterLeft {
    padding: 10px 0;
}

.filterLeft button {
    background: transparent;
    border: 0;
    color: #fff;
    font-size: 14px;
    border: 1px solid #fff;
    border-radius: 100px;
    padding: 8px 20px;
    margin-right: 10px;
}

.filterLeft button.active {
    background: #fff;
    color: #000;
    font-weight: 600;
}

button.inactivebt {
    border: 1px solid #EF3D16;
    font-size: 14px;
    background: transparent;
    color: #EF3D16;
    border-radius: 100px;
    padding: 4px 18px;
    min-width: 83px;
    text-align: center;
}

button.activebt {
    border: 1px solid #2BC155;
    font-size: 14px;
    background: transparent;
    color: #fff;
    border-radius: 100px;
    padding: 4px 18px;
    min-width: 83px;
    text-align: center;
    background: #2BC155;
}
.IndiavalForm {
    background: #151617;
    padding: 28px;
    border-radius: 10px;
    margin: 33px 0 0;
}
.IndiavalForm h2 {
    color: #fff;
    margin-bottom: 30px;
}
.IndiavalForm .formGroup label {
    color: #fff;
    width: 100%;
    margin-bottom: 4px;
}
.IndiavalForm .formGroup input {
    width: 100%;
    height: 49px;
    background: #000;
    border: 1px solid #6B6B6B;
    border-radius: 6px;
    color: #6d6d6d;
    padding: 0 18px;
    outline: none;
}
.IndiavalForm .formGroup {
    margin-bottom: 20px;
}
.IndiavalForm .formGroup select {
    width: 100%;
    height: 49px;
    background: #000;
    border: 1px solid #6B6B6B;
    border-radius: 6px;
    padding: 0 18px;
    outline: none;
    color: #6d6d6d;
}
.upoadImage {
    position: relative;
    border: 1px dashed #6B6B6B;
    border-radius: 6px;
    width: 100%;
    height: 300px;
    pointer-events: none;
}
.upoadImage input[type="file"] {
    position: absolute;
    font-size: 0;
    background: transparent;
    border: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: 20px;
    cursor: pointer;
    pointer-events: initial;
}
.preview p {
    color: #6d6d6d;
    font-size: 16px;
}
.ImagePrev img {
    position: absolute !important;
    left: 0;
    top: 0;
    z-index: 1;
    right: 0;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    border-radius: 5px;
    
}
.preview {
    position: absolute;
    background: #000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    flex-direction: column;
    z-index: 1;
}
.preview i {
    color: #6d6d6d;
    font-size: 26px;
    margin: 0 auto 10px;
    display: table;
}
.ImagePrev i {
    color: #ff6464;
    position: absolute;
    right: -9px;
    top: -6px;
    z-index: 9;
    font-size: 24px;
}
@media (max-width:1650px)
{
    .tableData table tr th {
        padding: 11px 13px;
    }
    .tableData table tr td {
        padding: 11px 13px;
    }
}

@media (max-width:1400px)
{
    .scrollTable table {
        width: 113%;
    }
    .scrollTable {
        overflow-x: auto;
    }
}
@media (max-width:1300px)
{
    .filterSlect {
        width: 67% !important;
    }
    .scrollTable table {
        width: 125%;
    }
}
@media (max-width:767px)
{
    .topFilter {
        flex-wrap: wrap;
    }
    .topFilter input {
        width: 100%;
        margin-bottom: 10px;
    }
    .filterSlect {
        width: 100% !important;
        flex-wrap: wrap;
    }
    .scrollTable table {
        width: 345%;
    }
}

.loyalityPointBtn {
    background: #03FE9D;
    border: 0;
    border-radius: 100px;
    padding: 9px 28px;
    font-weight: 600;
    margin-left: auto;
}