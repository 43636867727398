
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 21px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 100px;
    width: auto !important;
    height: auto !important;
}

.slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 2px;
    bottom: 2px;
    background-color: #000;
    transition: .4s;
    border-radius: 100px;

}

.switch input:checked+.slider {
    background-color: #03fe9d;
    width: auto;
    height: auto;
}

.switch input:focus+.slider {
    box-shadow: 0 0 1px #03fe9d;
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

.slider .round {
    border-radius: 34px;
}

.slider .round:before {
    border-radius: 50%;
}