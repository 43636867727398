.input {
  height: 50px;
  width: 100%;
  border-radius: 100px;
  padding: 0 19px;
  border: 1px solid #6B6B6B;
  background: #000;
  font-size: 14px;
  outline: none;
  color: white;
}

.container {
  background: #151617;
  padding: 28px;
  border-radius: 10px;
  margin: 33px 0 0;
}

.heading {
  color: #fff;
  font-size: 26px;
  text-align: center;
  margin: 4px 0 30px;
}

.label {
  font-size: 14px;
  width: 100%;
  color: #fff;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}

.submit {
  background: #03FE9D;
  font-weight: 600;
  border: 0;
  font-size: 16px;
  margin-top: 10px;
  color: black;
  padding: 13px 80px;
  border-radius: 100px;
}

.editor {
  height: 30vh !important;
  width: 78vw !important;
  overflow: auto !important;
}

.file {
  display: none;
}

.blogImage {
  border-radius: 100%;
}

.uploadButton {
  border: 1px solid white;
  padding: 12px;
  border-radius: 100%;
  cursor: pointer;
}