.topCms {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 70px 0 40px;
}

.topCms ul {
    padding: 0;
    margin: 0;
    display: flex;
}

.topCms ul li {
    list-style: none;
    margin-right: 33px;
    cursor: pointer;
}
.simBarTab ul li {
    margin-right: 12px;
  }
  .simBarTab ul li p {
    margin: 0;
    border: 1px solid #fff;
    padding: 11px 14px;
    border-radius: 7px;
  }
  .simBarTab ul li .active p
  {

  }
.topCms ul li p {
    color: #fff;
    text-decoration: none;
}

.topCms button {
    border: 1px solid #B1CBFF;
    background: transparent;
    color: #fff;
    font-size: 14px;
    padding: 9px 19px;
    border-radius: 100px;
}

.tabData {
    background: #101111;
    border-radius: 19px;
    padding: 36px;
}

.tabDtahd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D9D9D966;
    padding-bottom: 20px;
    margin-bottom: 35px;
}

.tabDtahd h4 {
    font-size: 26px;
    color: #fff;
    margin: 0;
}

.tabDtahd button {
    border: 1px solid #c4c4c4;
    background: transparent;
    color: #fff;
    font-size: 14px;
    padding: 11px 25px;
    border-radius: 100px;
}

.tabDtahd button img {
    margin-right: 7px;
    max-width: 16px;
}

.tabData p {
    color: #fff;
    font-size: 17px;
    margin-bottom: 21px;
}

.topCms ul li.active p {
    color: #03FE9D;
    font-weight: 600;
    border-bottom: 2px solid #03FE9D;
    padding-bottom: 7px;
}

.contactUS ul li span {
    color: #ffffffb3;
    width: 240px;
}

.contactUS ul li {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
}

.contactUS ul {
    padding: 0;
    margin: 0;
}

.contactUS ul li label {
    color: #fff;
}

.Social {
    display: flex;
    align-items: center;
}

.Social a {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1F5EFB;
    border-radius: 50px;
    color: #fff;
    text-decoration: none;
    margin-right: 12px;
}

.editor {
    height: 50vh !important;
    width: 100% !important;
    overflow: auto !important;
}

.loading {
    text-align: center;
    color: white;
}

.input {
    border: 1px solid #4F4F4F;
    width: 100%;
    background: transparent;
    border-radius: 100px;
    height: 55px;
    font-weight: 400;
    padding: 0 21px;
    font-size: 16px;
    color: #9A9AB0;
    outline: none;
}

.label {
    font-size: 16px;
    width: 100%;
    color: #CBCBCB;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
}

.error {
    color: red !important;
    font-size: 12px;
    margin-bottom: 0px !important;
    margin-left: 5px;
    font-weight: 600;
}
@media (max-width:991px)
{
    .topCms {
        margin: 30px 0 17px;
    }
    .tabData {
        padding: 30px;
    }
    .topCms ul li p {
        font-size: 15px;
    }
    .topCms ul li  {
        margin-right: 27px
    }
}
@media (max-width:767px)
{
    .topCms ul {
        flex-wrap: wrap;
        justify-content: center;
    }
    .topCms ul li p {
        font-size: 14px;
    }
    .topCms ul li  {
        margin-right: 22px
    }
    .tabDtahd {
       
        padding-bottom: 23px;
        margin-bottom: 19px;
        flex-wrap: wrap;
    }
    .tabData {
        padding: 15px;
    }
    .tabDtahd h4 {
        font-size: 21px;
        margin: 0 0 14px;
    }
    .tabDtahd button {
        font-size: 13px;
        padding: 9px 25px;
    }
    .tabData p {
        font-size: 15px;
    }
}