.filterSlect input {
    height: auto;
    color: white;
}

.filterSlect {
    width: 40%;
    /* margin-left: 4px; */
}

.loyalityPointBtn {
    background: #03FE9D;
    border: 0;
    border-radius: 100px;
    padding: 9px 28px;
    font-weight: 600;
    margin-left: auto;
    margin-bottom: 10px;
}
.scrollTable table tr td .loyalityPointBtn
{
    background: #03FE9D;
    border: 0;
    border-radius: 100px;
    padding: 6px 13px;
    font-weight: 600;
    margin-left: auto;
    margin-bottom: 0;
}
.loadingText {
    font-size: 19px;
}
@media (max-width:1650px)
{
    .tableData table tr th {
        padding: 11px 13px;
    }
    .tableData table tr td {
        padding: 11px 13px;
    }
}

@media (max-width:1400px)
{
    .scrollTable table {
        width: 113%;
    }
    .scrollTable {
        overflow-x: auto;
    }
}
@media (max-width:1300px)
{
    .filterSlect {
        width: 67% !important;
    }
    .scrollTable table {
        width: 125%;
    }
}
@media (max-width:767px)
{
    .topFilter {
        flex-wrap: wrap;
    }
    .topFilter input {
        width: 100%;
        margin-bottom: 10px;
    }
    .filterSlect {
        width: 100% !important;
        flex-wrap: wrap;
    }
    .scrollTable table {
        width: 345%;
    }
    .topFilter {
        flex-wrap: wrap;
    }
}

.download_btn {
    background: #03bb74;
    border: 0;
    border-radius: 100px;
    padding: 9px 28px;
    font-weight: 600;
    margin-left: auto;
    margin-bottom: 10px;
    color: #fff;
    text-decoration: none;
  }
.mainTabbing {
    display: flex;
    justify-content: space-between;
    padding-bottom: 21px;
}
.simBarTab ul li.active p
{
    border: 1px solid !IMPORTANT;
    padding: 11px 14px !important;
    border-radius: 7px !IMPORTANT;
    font-weight: 5 !important;
}
body .simBarTab ul li {
    margin-right: 12px;
  }
  .simBarTab ul li p {
    margin: 0;
    border: 1px solid #fff;
    padding: 11px 14px;
    border-radius: 7px;
  }
.topCms ul {
    padding: 0;
    margin: 0;
    display: flex;
}

.topCms ul li {
    list-style: none;
    margin-right: 33px;
    cursor: pointer;
}

.topCms ul li p {
    color: #fff;
    text-decoration: none;
}
.topCms ul li.active p {
    color: #03FE9D;
    font-weight: 600;
    border-bottom: 2px solid #03FE9D;
    padding-bottom: 7px;
}
.total_count {
    display: inline-block;
    padding: 0.25em 0.5em;
    color: #fff; /* Text color */
    border-radius: 0.25em; /* Rounded corners */
  }
  
  .order_count {
    font-weight: bold;
    margin-left: 0.5em; /* Adjust spacing between label and count */
  }


.formDate
{
    display: flex;
}
.formDate .inputGoup
{
    padding-left: 14px;
}
.formDate .inputGoup label
{
    color: #fff;
    font-size: 14px;
    margin-right: 0px;
    width: 100%;
    display: table;
    margin-bottom: 4px;
}
.formDate .inputGoup input[type="date"]
{
    background: #1a1d1e;
    border: 1px solid #ffffff17;
    padding: 7px 10px;
    border-radius: 5px;
    color: #fff;
    appearance: none;
    font-size: 15px;

}
