.container {
  background-color: #1A1D1E;
  padding: 15px 20px;
  border-radius: 7px;
}

.blogImage {
  border-radius: 5px;
}

.outerBlog {
  margin: 34px 0 32px;
}

.outerBlog h4 {
  color: #fff;
  font-size: 20px;
}

.outerBlog img {
  width: 100% !important;
  border-radius: 12px;
  height: 400px !important;
  object-fit: cover;
}

.outerBlog h4 span {
  color: #03FE9D;
}

.outerBlog h2 {
  margin-top: 23px;
}

.CommentsSec h5 {
  color: #fff;
  margin-bottom: 15px;
}

.commnetBox {
  border: 1px solid #dddddd26;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 21px;
  background: #0f1111;
  position: relative;
}

.commentHead {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

span.usrImage {
  background: #ffffff4a;
  padding: 5px;
  border-radius: 4px;
  border-radius: 100px;
  width: 60px;
  height: 60px;
  margin-right: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

span.usrImage svg {
  width: 33px;
  color: #fff;
  margin: 0 !important;
}

.commentHead h6 {
  margin: 0;
  font-size: 19px;
  text-transform: capitalize;
}

span.thumb svg {
  width: 31px;
  color: #03FE9D;
}

span.thumb {
  position: absolute;
  right: 12px;
  top: 16px;
}

.Usrnm p {
  margin: 0;
  font-size: 14px;
  color: #a8a8a8 !IMPORTANT;
}

.CommentsSec {
  max-height: 450px;
  overflow-y: auto;
}

.commentHead h6 span {
  background: #1ffe9d;
  padding: 2px 19px;
  border-radius: 3px;
  font-size: 14px;
  margin-left: 6px;
  color: #000;
}

.Loading {
  color: #fff;
  text-align: center;
  margin-top: 100px;
  font-size: 22px;
}