.dropDownList ul {
    padding: 0px !important;
    margin: 0;
}

.dropDownList {
    background-color: #1A1D1E !important;
}

.dropDownList a {
    color: #fff;
}

.dropDownList a:hover {
    background-color: #1A1D1E;
    color: #fff;
}