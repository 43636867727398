.profileLeft,
.profileRight {
    background: #101111;
    padding: 35px;
    border-radius: 10px;
    height: 100%;
}

.profilelefttp,
.profileRight h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc61;
    padding-bottom: 25px;
    margin-bottom: 25px;
    color: #fff;
}

.profilelefttp h4 {
    font-size: 23px;
    color: #fff;
    margin: 0;
}

.profileImage {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dcdcdc61;
    padding-bottom: 25px;
    margin-bottom: 25px;
}

.profileImage span img {
    max-width: 72px;
    max-height: 72px;
    border-radius: 100px;
    width: 72px;
    object-fit: cover;
    margin-right: 18px;
}

.profileData h6 {
    font-size: 19px;
    color: #fff;
    margin: 0;
}

.profileData label {
    font-size: 14px;
    color: #fff;
    margin: 0;
}

.profileData h5 {
    font-size: 15px;
    color: #898989;
    margin: 3px 0 0;
}

.profileLeft ul {
    padding: 0;
    margin: 0;
}

.profileLeft ul li {
    color: #fff;
    font-size: 14px;
    list-style: none;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
}

.profileLeft ul li span {
    width: 160px;
}

.profileRight .formGroup label {
    width: 100%;
    color: #fff;
    font-size: 16px;
}

.profileRight .formGroup input {
    background-color: transparent !important;
    border: 0;
    font-size: 14px;
    color: #898989 !important;
    width: 100%;
    border-bottom: 1px solid #dcdcdc61;
    padding-bottom: 8px;
    padding-top: 5px;
    background: transparent !important;
    outline: none;
}

.profileRight .formGroup {
    margin-bottom: 22px;
    position: relative;
}

.profileRight .formGroup .changePassword {
    position: absolute;
    right: 0;
    border: 1px solid #03FE9D;
    top: 8px;
    background: #000;
    color: #fff;
    border-radius: 10px;
    padding: 7px 15px;
    font-size: 14px;
}

.secure {
    background: #000000;
    padding: 20px 25px;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.secure h6 {
    color: #fff;
    margin: 0;
}

.secure a {
    background: #03FE9D;
    color: #000;
    text-decoration: none;
    font-size: 15px;
    padding: 9px 23px;
    font-weight: 600;
    border-radius: 10px;
}

.input {
    border: 1px solid #4F4F4F;
    width: 100%;
    background: transparent;
    border-radius: 100px;
    height: 55px;
    font-weight: 400;
    padding: 0 21px;
    font-size: 18px;
    color: #9A9AB0;
    outline: none;
}

.modal-content {
  background-color: #0D0D0D !important;
  padding: 40px 20px;
}

.modal-dialog {
  max-width: 600px;
}
.InputState {
    background-color: transparent !important;
    border: 0;
    font-size: 14px;
    color: #898989 !important;
    width: 100%;
    border-bottom: 1px solid #dcdcdc61;
    padding-bottom: 8px;
    padding-top: 5px;
    background: transparent !important;
    outline: none;
    height: 55px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-bottom: 13px;
}
@media (max-width:991px)
{
    .profileLeft, .profileRight {
        padding: 18px;
    }
}
@media (max-width:767px)
{
    .secure {
        padding: 14px 12px;
    }
}